import React from "react";
import logo from "./logo.svg";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  return (
    <div className="h-screen  w-full flex flex-col items-center gap-8 pt-6">
      <div>
        <img
          onClick={() => {
            navigate("/");
          }}
          className="cursor-pointer"
          src={logo}
          alt=""
        />
      </div>
      <form
        action=""
        className="h-[300px] rounded-[10px] w-[350px] border-[2px] p-6 pt-5 pb-5 border-[red] flex flex-col "
      >
        <div className=" w-full mb-[6px]">
          <h1 className="text-[30px]  formH1 ">Sigh in</h1>
        </div>
        <div className=" w-full mb-[4px]">
          <p className="text-[12px] font-[600]">
            Enter mobile phone number or email
          </p>
        </div>
        <div className=" w-full mb-[12px]">
          <input type="text" className="border-[1px] formInput border-[gray] w-full h-[30px] rounded-[3px]" />
        </div>
        <div className=" w-full mb-[20px]">
          <button className="w-full h-[30px] rounded-[6px] flex items-center justify-center bg-[#EA580C]">Continue</button>
        </div>
        <div className=" w-full">
          <p>By continuing, you agree to Shopcart's </p>
        </div>
      </form>
    </div>
  );
}

export default Login;
